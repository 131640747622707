import { Callout, Card, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { FC } from 'react';
import { CustomGraph } from '../../../feature/feature-views/graph-types';
import { FEATURE_SERVER_GROUPS, FEATURE_SERVICE_GLOBAL_SLO } from '../../../feature/graph-configuration';
import { ServerGroupStatus } from '../../../types/tecton_proto/common/server_group_status';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import { ServerGroupSelectable } from './FeatureServerGroupLists';
import MonitoringGraphs from './MonitoringGraphs';
import { ServerGroupStatusType } from './RealtimeServerMonitoring';

import styled from '@emotion/styled';
import SLOReliability30Days from './SLOReliability30Days';

const SLOWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeatureServerGroup: FC<{
  selectedServerGroup?: ServerGroupSelectable;
  serverGroupStatusMap: ServerGroupStatusType;
  monitoringDateRange: MonitoringDateRange;
  selectedWorkspace: string;
}> = ({ monitoringDateRange, serverGroupStatusMap, selectedWorkspace, selectedServerGroup }) => {
  const serverGroupName = selectedServerGroup?.serverGroupId
    ? `${selectedWorkspace}/${selectedServerGroup?.name}`
    : `default`;

  const customGraphs: Record<string, React.ReactNode> = {
    [CustomGraph.FEATURE_SERVICE_GLOBAL_SLO_RELIABILITY]: (
      <>
        <Card title="Reliability SLO (30 days)" noScroll centerBody>
          <SLOWrapper>
            <SLOReliability30Days monitoringDateRange={monitoringDateRange} />
          </SLOWrapper>
        </Card>
      </>
    ),
  };
  return (
    <>
      <FlexGroupWrapper>
        <FlexItem>
          {selectedServerGroup?.status && (
            <Callout
              mode={
                serverGroupStatusMap[selectedServerGroup?.status ?? ServerGroupStatus.SERVER_GROUP_STATUS_UNSPECIFIED]
                  .status
              }
              title={`Status: ${
                serverGroupStatusMap[selectedServerGroup?.status ?? ServerGroupStatus.SERVER_GROUP_STATUS_UNSPECIFIED]
                  .title
              }`}
            >
              {selectedServerGroup.description}
            </Callout>
          )}
        </FlexItem>
        <FlexItem>
          <MonitoringGraphs
            monitoringDateRange={monitoringDateRange}
            graphsData={FEATURE_SERVICE_GLOBAL_SLO}
            customGraphRecord={customGraphs}
            workspace={selectedWorkspace ?? ''}
          />
        </FlexItem>
        <FlexItem>
          <MonitoringGraphs
            monitoringDateRange={monitoringDateRange}
            graphsData={FEATURE_SERVER_GROUPS}
            customGraphRecord={{}}
            workspace={selectedWorkspace ?? ''}
            serverGroupName={`${serverGroupName}`}
          />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default FeatureServerGroup;
